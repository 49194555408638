/* eslint-disable @typescript-eslint/no-untyped-public-signature */
import React from 'react';
import s from './SingleSelectionFilter.scss';

import {RadioOption} from '../RadioOption/RadioOption';
import {DEFAULT_COLLECTION_ID} from '../../../constants';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import * as _ from 'lodash';
import {withGlobals} from '../../../globalPropsContext';
import {IGalleryGlobalProps} from '../../galleryGlobalStrategy';

export interface ISingleSelectionFilterProps extends IGalleryGlobalProps {
  options: {id: string; name: string}[];
  filterId: number;
  selected: string;
}

@withGlobals
@withTranslations()
export class SingleSelectionFilter extends React.Component<ISingleSelectionFilterProps & IProvidedTranslationProps> {
  private handleChange(e) {
    const selectedCollectionId = e.target.value;
    this.props.globals.filterProducts(this.props.filterId, selectedCollectionId);
  }

  public shouldComponentUpdate(nextProps: ISingleSelectionFilterProps) {
    return !_.isEqual(this.props, nextProps);
  }

  public render() {
    const {options} = this.props;
    const {mainCollectionId} = this.props.globals;

    const optionsWithoutMainCollection = options.filter((option) => {
      return option.id !== DEFAULT_COLLECTION_ID && option.id !== mainCollectionId;
    });

    return (
      <ul className={s.options} role="radiogroup">
        <li data-hook="single-selection-option-main" key={mainCollectionId} className={s.option}>
          <RadioOption
            onChange={(e) => this.handleChange(e)}
            label={this.props.t('allCollectionsFilterButtonText')}
            id={mainCollectionId}
            selected={this.props.selected === mainCollectionId}
          />
        </li>
        {optionsWithoutMainCollection.map((option, index) => (
          <li data-hook={`single-selection-option-${index + 1}`} key={option.id} className={s.option}>
            <RadioOption
              onChange={(e) => this.handleChange(e)}
              label={option.name}
              id={option.id}
              selected={this.props.selected === option.id}
            />
          </li>
        ))}
      </ul>
    );
  }
}
