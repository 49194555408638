/* eslint-disable react/button-has-type */
/* eslint-disable @typescript-eslint/no-untyped-public-signature */
import React from 'react';
import s from './LoadMoreButton.scss';
import classNames from 'classnames';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {withGlobals} from '../../../../globalPropsContext';
import {IGalleryGlobalProps} from '../../../galleryGlobalStrategy';

interface LoadMoreButtonProps {
  loadMoreClicked: any;
}

@withGlobals
@withTranslations()
export class LoadMoreButton extends React.Component<
  LoadMoreButtonProps & IGalleryGlobalProps & IProvidedTranslationProps
> {
  public render() {
    return (
      <button
        data-hook="load-more-button"
        className={classNames(s.loadMore, {[s.isMobile]: this.props.globals.isMobile})}
        onClick={this.props.loadMoreClicked}>
        {this.props.t('loadMoreButtonText')}
      </button>
    );
  }
}
