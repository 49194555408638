/* eslint-disable @typescript-eslint/no-untyped-public-signature */
import React from 'react';
import s from './EmptyGallery.scss';
import classNames from 'classnames';
import {
  IProvidedTranslationProps,
  withTranslations,
} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/translations';
import {withGlobals} from '../../../../globalPropsContext';
import {IGalleryGlobalProps} from '../../../galleryGlobalStrategy';

export interface EmptyGalleryProps extends IGalleryGlobalProps, IProvidedTranslationProps {
  hasFilters: boolean;
}

@withGlobals
@withTranslations()
export class EmptyGallery extends React.Component<EmptyGalleryProps> {
  public render() {
    const {hasFilters} = this.props;
    return (
      <header data-hook="empty-gallery" className={classNames(s.emptyGallery, {[s.filterEmptyState]: hasFilters})}>
        <h2 className={s.titleSub}>
          {hasFilters ? this.props.t('noProductsFilteredMessageText') : this.props.t('noProductsMessageText')}
        </h2>
      </header>
    );
  }
}
